import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { sec } from '../../security.js';
import {
  // \n
  ChartXAxisOptions,
  ChartYAxisGroups,
  DataTrailsOverallResponse,
  DataTrailsOverallResponseRaw,
  DataTrailsRequest,
  DataTrailsResponse,
  RowboatDatasource,
  StatsRequest,
  StatsRequestTable
} from './types/api/index.js';
import { format, sub } from 'date-fns';

const { VITE_API_HOST } = import.meta.env;

const paramsForDataTrailsRequest = ({ datasource, dateRange, wifiStatus, countries }: DataTrailsRequest, type: string) => {
  const params: Record<string, string | boolean | number> = {
    orient: 'records',
    indent: 0,
    date_column: 'record_date',
    by_ds: true,
    by_date: true,
    datasources: datasource.join(',').toLowerCase(),
    type
  };

  if (dateRange && dateRange.length === 2) {
    params.date_range = `${dateRange[0]}|${dateRange[1]}`;
  }
  if (wifiStatus === 'w/ wifi') {
    params.only_wifi = true;
  } else if (wifiStatus === 'w/o wifi') {
    params.no_wifi = true;
  }
  if (countries && countries.length > 0) {
    params.countries = countries.join(',');
  }
  return params;
};

// Define a service using a base URL and expected endpoints
export const api = createApi({
  reducerPath: 'statsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: VITE_API_HOST,
    prepareHeaders: async (headers) => {
      const access_token = await sec.getAccessTokenSilently()();
      if (access_token) headers.set('Authorization', `Bearer ${access_token}`);
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getDeliveryLagStats: builder.query<DataTrailsResponse[], DataTrailsRequest>({
      query: (filters: DataTrailsRequest) => {
        return {
          method: 'GET',
          url: '/stats',
          params: paramsForDataTrailsRequest(filters, 'delivery_lag')
        };
      }
    }),
    getDeliveryLagOverall: builder.query<DataTrailsOverallResponse[], DataTrailsRequest>({
      query: (filters: DataTrailsRequest) => {
        return {
          method: 'GET',
          url: '/stats',
          params: paramsForDataTrailsRequest(filters, 'typical_delivery')
        };
      },
      transformResponse: (response: DataTrailsOverallResponseRaw[]) => {
        return response.map((d) => {
          const deliveries = Object.values(d.deliveries);
          const total = deliveries.reduce((a, b) => a + b, 0);
          const deliveries_pct = Object.fromEntries(Object.entries(d.deliveries).map(([k, v]) => [k, v / total]));
          return {
            datasource: d.datasource,
            deliveries: d.deliveries,
            deliveries_pct
          };
        });
      }
    }),

    getDatasources: builder.query<RowboatDatasource[], void>({
      query: () => ({
        method: 'GET',
        url: '/ege/data_sources'
      })
    }),
    getData: builder.query<[number, number, number, number, unknown, unknown, unknown][], StatsRequest | StatsRequestTable>({
      query: ({
        // \n
        filters,
        // chartv2_x_axis,
        // chartv2_y_group,
        chartv2_date_field,
        ...rest
      }) => {
        const params: Record<string, string | boolean | number> = {
          orient: 'values',
          indent: 0
        };
        for (const filter of filters) {
          if (filter.column === 'date' && typeof filter.values === 'number') {
            // params.last_n_days = filter.values;
            const d = new Date();
            const now = format(d, 'yyyy-MM-dd');
            const then = format(sub(d, { days: filter.values }), 'yyyy-MM-dd');
            params.date_range = [then, now].join('|');
          }
          if (filter.column === 'date' && Array.isArray(filter.values) && filter.values.length === 2) {
            params.date_range = filter.values.join('|');
          }
          if (filter.column === 'country' && Array.isArray(filter.values) && filter.values.length > 0) {
            params.countries = filter.values.join(',');
          }
          if (filter.column === 'datasource' && Array.isArray(filter.values) && filter.values.length > 0) {
            params.datasources = filter.values.join(',');
          }
          if (filter.column === 'wifi status' && filter.values === false) {
            params.no_wifi = true;
          }
          if (filter.column === 'wifi status' && filter.values === true) {
            params.only_wifi = true;
          }
        }

        params.date_column = chartv2_date_field;

        if ('chartv2_x_axis' in rest) {
          // CHART, MAP
          if (rest.chartv2_y_group === ChartYAxisGroups.COUNTRY) {
            params.by_country = true;
          } else if (rest.chartv2_y_group === ChartYAxisGroups.HAS_WIFI) {
            params.by_has_wifi = true;
          } else if (rest.chartv2_y_group === ChartYAxisGroups.DATASOURCE) {
            params.by_ds = true;
          }
          if (rest.chartv2_x_axis === ChartXAxisOptions.COUNTRY) {
            params.by_country = true;
          } else if (rest.chartv2_x_axis === ChartXAxisOptions.DATE) {
            params.by_date = true;
          } else if (rest.chartv2_x_axis === ChartXAxisOptions.HOURLY) {
            params.by_hod = true;
          } else if (rest.chartv2_x_axis === ChartXAxisOptions.WEEKLY) {
            params.by_week = true;
          } else if (rest.chartv2_x_axis === ChartXAxisOptions.MONTHLY) {
            params.by_month = true;
          }
        } else {
          // TABLE
          if (rest.tablev2_include_date) {
            params.by_date = true;
          }
          if (rest.tablev2_include_geom) {
            params.by_country = true;
          }
          if (rest.tablev2_include_wifi) {
            params.by_has_wifi = true;
          }
          if (rest.tablev2_include_hod) {
            params.by_hod = true;
          }
          if (rest.tablev2_include_datasource) {
            params.by_ds = true;
          }
        }

        // always have datasources:
        if (!params.datasources) {
          params.datasources = 'qq,bb';
        }

        return {
          method: 'GET',
          url: '/stats',
          params
        };
      }
    })
  })
});

export const { useGetDataQuery, useGetDeliveryLagOverallQuery, useGetDatasourcesQuery, useGetDeliveryLagStatsQuery } = api;
