import type { PayloadAction, Slice } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Feature } from 'geojson';

import { RootState } from '@store';
import { BasemapLayerOptions } from '@types';
import { TackleboxTools } from './const';
import { JobParams, MapViewport } from './types';

export interface SliceState {
  initialMapViewport: MapViewport;
  delayedMapViewport?: MapViewport;
  delayedMapBounds?: [[number, number], [number, number]];
  basemap: BasemapLayerOptions;
  selectedTool?: string;
  polygons: Feature[];
  adminLayer: string | null;
  jobName: string;
}

const initialState: SliceState = {
  initialMapViewport: {
    latitude: 30,
    longitude: 0,
    zoom: 2
  },
  basemap: BasemapLayerOptions.mapboxLight,
  polygons: [],
  selectedTool: TackleboxTools.TITO,
  adminLayer: null,
  jobName: ''
};

export const tackleboxSlice: Slice<SliceState> = createSlice({
  name: 'tackleboxSlice',
  initialState,
  reducers: {
    setBasemap: (state, action: PayloadAction<BasemapLayerOptions>) => {
      state.basemap = action.payload;
    },
    setDelayedMapViewport: (state, action: PayloadAction<{ viewport: MapViewport; bounds: [[number, number], [number, number]] }>) => {
      state.delayedMapViewport = action.payload.viewport;
      state.delayedMapBounds = action.payload.bounds;
    },
    setSelectedTool: (state, action: PayloadAction<string>) => {
      state.selectedTool = action.payload;
    },
    setPolygons: (state, action: PayloadAction<Feature[]>) => {
      state.polygons = action.payload;
    },
    addPolygon: (state, action: PayloadAction<Feature>) => {
      state.polygons.push(action.payload);
    },
    setParams: (state, action: PayloadAction<JobParams>) => {
      const { features } = action.payload;
      state.polygons = features as Feature[];
      state.jobName = action.payload.name;
    },
    setAdminLayer: (state, action: PayloadAction<string>) => {
      state.adminLayer = action.payload;
    },
    setJobName: (state, action: PayloadAction<string>) => {
      state.jobName = action.payload;
    }
  }
});

const selectInitialMapViewport = (store: RootState) => store.tackleboxSlice.initialMapViewport;
const selectDelayedMapViewport = (store: RootState) => store.tackleboxSlice.delayedMapViewport;
const selectDelayedMapBounds = (store: RootState) => store.tackleboxSlice.delayedMapBounds;
const selectBasemap = (store: RootState) => store.tackleboxSlice.basemap;
const selectSelectedTool = (store: RootState) => store.tackleboxSlice.selectedTool;
const selectPolygons = (store: RootState) => store.tackleboxSlice.polygons;
const selectAdminLayer = (store: RootState) => store.tackleboxSlice.adminLayer;
const selectJobName = (store: RootState) => store.tackleboxSlice.jobName;

const stateForMap = createSelector(
  [selectInitialMapViewport, selectDelayedMapViewport, selectDelayedMapBounds, selectBasemap, selectPolygons, selectAdminLayer],
  (initialMapViewport, delayedMapViewport, delayedMapBounds, basemap, polygons, adminLayer) => ({
    initialMapViewport,
    delayedMapViewport,
    delayedMapBounds,
    basemap,
    polygons,
    adminLayer
  })
);

const stateForTool = createSelector([selectSelectedTool, selectPolygons, selectJobName], (selectedTool, polygons, jobName) => ({
  selectedTool,
  polygons,
  jobName
}));

export const sliceSelectors = {
  stateForMap,
  stateForTool
};

// Action creators are generated for each case reducer function
export const {
  // \n
  setBasemap,
  setDelayedMapViewport,
  setSelectedTool,
  setPolygons,
  setParams,
  addPolygon,
  setAdminLayer,
  setJobName
} = tackleboxSlice.actions;

export default tackleboxSlice.reducer;
