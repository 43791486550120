import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { sec } from '../../security';
import { GADMCountry } from './types/service';
import { DataSinkResponse, DatasourceFileTransferStatusResponse, ImpossibleJourneyResponse, OverlapRow, RowboatDatasourceStats } from './types';

const { VITE_SERVICE_HOST } = import.meta.env;

// Define a service using a base URL and expected endpoints
export const service = createApi({
  tagTypes: [],
  reducerPath: 'statsService',
  baseQuery: fetchBaseQuery({
    baseUrl: VITE_SERVICE_HOST,
    prepareHeaders: async (headers) => {
      const access_token = await sec.getAccessTokenSilently()();
      if (access_token) headers.set('Authorization', `Bearer ${access_token}`);
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getOverlapsSnapshot: builder.query<OverlapRow[], void>({
      query: () => ({
        method: 'GET',
        url: '/v0/stats/datasources/overlaps'
      })
    }),
    getDataSinks: builder.query<DataSinkResponse, void>({
      query: () => ({
        method: 'GET',
        url: '/v0/stats/datasources/data_sinks'
      })
    }),
    getImpossibleJourneys: builder.query<ImpossibleJourneyResponse, void>({
      query: () => ({
        method: 'GET',
        url: '/v0/stats/datasources/impossible_journeys'
      })
    }),
    getDatasourceTransferStats: builder.query<DatasourceFileTransferStatusResponse, void>({
      query: () => ({
        method: 'GET',
        url: '/v0/stats/datasources/transfers'
      })
    }),
    getDatasourceStats: builder.query<RowboatDatasourceStats[], void>({
      query: () => ({
        method: 'GET',
        url: '/v0/stats/datasources'
      })
    }),
    getGADMCountries: builder.query<GADMCountry[], void>({
      query: () => ({
        method: 'GET',
        url: '/v0/meta/gadm'
      })
    })
  })
});

export const {
  useGetGADMCountriesQuery,
  useGetDatasourceStatsQuery,
  useGetDatasourceTransferStatsQuery,
  useGetDataSinksQuery,
  useGetImpossibleJourneysQuery,
  useGetOverlapsSnapshotQuery
} = service;
