import { Auth0Provider } from '@auth0/auth0-react';

const { VITE_DISABLE_AUTH } = import.meta.env;

export interface AuthProviderProps {
  children: JSX.Element | JSX.Element[];
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  if (VITE_DISABLE_AUTH) {
    return children;
  }
  return (
    <Auth0Provider
      domain="dev-6zqmkqm0slg0vtpl.us.auth0.com"
      clientId="KGyItzBgDlczMYNSUwhC2DWKZxmiWbdc"
      authorizationParams={{
        redirect_uri: window.location.origin + `?path=${window.location.pathname}`,
        audience: 'https://waitress.epochgeo.com'
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
