export enum TackleboxTools {
  TITO = 'tito'
}

// mapping for tacklebox tool names
export const TackleboxToolDisplayInfo = {
  [TackleboxTools.TITO]: { displayName: 'TiTo', description: 'Tell me identifiers common in Two or more polygons' }
};

// kilometer squared
export const MAX_POLYGONS_AREA = 1_000;
