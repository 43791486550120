import type { PayloadAction, Slice } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@store';
import { CandyCaneYAxisType, ChartDateFields, DisplayPanelView, Filter, GADMCountry, MapViewport, RowboatDatasource } from './types';
import { format, subDays } from 'date-fns';
import { BasemapLayerOptions } from '@types';

export interface SliceState {
  initialMapViewport: MapViewport;
  delayedMapViewport?: MapViewport;
  delayedMapBounds?: [[number, number], [number, number]];
  basemap: BasemapLayerOptions;
  datastatsSubView: DisplayPanelView;
  dateRange: [string, string];
  country: GADMCountry[];
  selectedDatasets: RowboatDatasource[];
  mapMetric: 'UADID' | 'COUNT';
  graphDateField: ChartDateFields;
  integrityYAxisType: CandyCaneYAxisType;
}

// Generate today's date
const today = new Date();
// Format the dates into 'yyyy-MM-dd' format
const formattedToday = format(today, 'yyyy-MM-dd');
const formattedTwelveWeeksAgo = format(subDays(today, 7), 'yyyy-MM-dd');

const initialState: SliceState = {
  initialMapViewport: {
    latitude: 30,
    longitude: 0,
    zoom: 2
  },
  basemap: BasemapLayerOptions.mapboxLight,
  datastatsSubView: 'map' as const,
  country: [],
  selectedDatasets: [],
  dateRange: [formattedTwelveWeeksAgo, formattedToday],
  mapMetric: 'COUNT' as const,
  graphDateField: ChartDateFields.RECORDED,
  integrityYAxisType: CandyCaneYAxisType.LOG
};

export const statsSlice: Slice<SliceState> = createSlice({
  name: 'statsSlice',
  initialState,
  reducers: {
    setGraphDateField: (state, action: PayloadAction<ChartDateFields>) => {
      state.graphDateField = action.payload;
    },
    setBasemap: (state, action: PayloadAction<BasemapLayerOptions>) => {
      state.basemap = action.payload;
    },
    setMapMetric: (state, action: PayloadAction<'UADID' | 'COUNT'>) => {
      state.mapMetric = action.payload;
    },
    setDelayedMapViewport: (state, action: PayloadAction<MapViewport>) => {
      state.delayedMapViewport = action.payload;
    },
    setStatsSubView: (state, action: PayloadAction<DisplayPanelView>) => {
      state.datastatsSubView = action.payload;
    },
    setDateRange: (state, action: PayloadAction<[string, string]>) => {
      state.dateRange = action.payload;
    },
    setCountry: (state, action: PayloadAction<GADMCountry[]>) => {
      state.country = action.payload;
    },
    setDatasources: (state, action: PayloadAction<RowboatDatasource[]>) => {
      state.selectedDatasets = action.payload;
    },
    addDataset: (state, action: PayloadAction<RowboatDatasource>) => {
      state.selectedDatasets = state.selectedDatasets ? [...state.selectedDatasets, action.payload] : [action.payload];
    },
    removeDataset: (state, action: PayloadAction<RowboatDatasource>) => {
      state.selectedDatasets = state.selectedDatasets?.filter((d) => d.short_name !== action.payload.short_name);
    },
    setIntegrityYAxisType: (state, action: PayloadAction<CandyCaneYAxisType>) => {
      state.integrityYAxisType = action.payload;
    }
  }
});

const selectInitialMapViewport = (store: RootState) => store.statsSlice.initialMapViewport;
const selectDelayedMapBounds = (store: RootState) => store.statsSlice.delayedMapBounds;
const selectBasemap = (store: RootState) => store.statsSlice.basemap;
const selectDateRange = (store: RootState) => store.statsSlice.dateRange;
const selectCountry = (store: RootState) => store.statsSlice.country;
const selectSelectedDatasets = (store: RootState) => store.statsSlice.selectedDatasets;
const selectMapMetric = (store: RootState) => store.statsSlice.mapMetric;
const selectDataStatsSubView = (store: RootState) => store.statsSlice.datastatsSubView;
const selectGraphDateField = (store: RootState) => store.statsSlice.graphDateField;
const selectIntegrityYAxisType = (store: RootState) => store.statsSlice.integrityYAxisType;

const stateForDisplayPanelMap = createSelector(
  [selectInitialMapViewport, selectDelayedMapBounds, selectBasemap, selectMapMetric],
  (initialMapViewport, delayedMapBounds, basemap, mapMetric) => ({
    initialMapViewport,
    delayedMapBounds,
    basemap,
    mapMetric
  })
);

const controlPanelSliceSelectors = createSelector([selectDateRange, selectCountry, selectSelectedDatasets], (dateRange, country, selectedDatasets) => ({
  dateRange,
  country,
  selectedDatasets
}));

const dataLagChartSelector = createSelector(
  [selectSelectedDatasets, selectDateRange, selectCountry, selectIntegrityYAxisType],
  (selectedDatasets, dateRange, country, integrityYAxisType) => ({
    selectedDatasets,
    dateRange,
    country,
    integrityYAxisType
  })
);

const statsMetricsPanelSelectorEx = createSelector([selectMapMetric, selectDataStatsSubView], (mapMetric, datastatsSubView) => ({
  mapMetric,
  datastatsSubView
}));

const statsMetricsPanelsSelector = createSelector(
  [selectSelectedDatasets, selectDateRange, selectCountry, selectGraphDateField],
  (selectedDatasets, dateRange, country, graphDateField) => {
    if (!dateRange || selectedDatasets.length === 0) {
      return null;
    }
    const filters: Filter[] = [
      {
        column: 'date' as const,
        values: dateRange
      },
      {
        column: 'datasource' as const,
        values: selectedDatasets.map((d) => d.short_name.toLowerCase())
      }
    ];
    if (country && country.length > 0) {
      filters.push({
        column: 'country' as const,
        values: country.map((d) => d.country_code)
      });
    }

    return {
      filters,
      chartv2_date_field: graphDateField,
      tablev2_include_date: false,
      tablev2_include_geom: false,
      tablev2_include_wifi: false,
      tablev2_include_hod: false,
      tablev2_include_datasource: true // by_ds => true
    };
  }
);

const statsFiltersSelector = createSelector(
  [selectSelectedDatasets, selectDateRange, selectCountry, selectMapMetric, selectGraphDateField],
  (selectedDatasets, dateRange, country, mapMetric, graphDateField) => {
    if (!dateRange || selectedDatasets.length === 0) {
      return { filters: [], selectedDatasets, mapMetric, graphDateField };
    }
    const filters: Filter[] = [
      {
        column: 'date' as const,
        values: dateRange
      },
      {
        column: 'datasource' as const,
        values: selectedDatasets.map((d) => d.short_name.toLowerCase())
      }
    ];
    if (country && country.length > 0) {
      filters.push({
        column: 'country' as const,
        values: country.map((d) => d.country_code)
      });
    }
    return { filters, selectedDatasets, mapMetric, graphDateField };
  }
);

export const sliceSelectors = {
  stateForDisplayPanelMap,
  controlPanelSliceSelectors,
  dataLagChartSelector,
  statsMetricsPanelsSelector,
  statsFiltersSelector,
  statsMetricsPanelSelectorEx
};

// Action creators are generated for each case reducer function
export const {
  // \n
  addDataset,
  removeDataset,
  setCountry,
  setDateRange,
  setDelayedMapViewport,
  setStatsSubView,
  setDatasources,
  setMapMetric,
  setBasemap,
  setGraphDateField,
  setIntegrityYAxisType
} = statsSlice.actions;

export default statsSlice.reducer;
