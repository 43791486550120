import { MenuProps } from '@mui/material';
import { SsidChartOutlined, FactCheckOutlined, ListAltOutlined, HomeRepairServiceOutlined } from '@mui/icons-material';
import { NavbarOptions } from './Views/Stats/types/Navbar';
import { hexToRgb } from './utils';

export const AUTH_FALLBACK = {
  loginWithRedirect: () => null,
  getAccessTokenSilently: async () => '',
  user: {},
  error: null,
  isLoading: false,
  logout: () => undefined
};

export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoic3VtYXJsaWRhc29uIiwiYSI6ImNsNG4yMGdoYjBjdnczanE4N3pyZXpkd2YifQ.5IFs5KRgvf8tSOklX2pu9g';
export const MENU_ITEM_MIN_WIDTH = 200;
export const MAP_PANEL_MAX_HEIGHT = 350;
export const MAP_TRANSITION_ZOOM = 10.2;
export const MAX_ROWS = 10_001;
export const REFETCH_THRESHOLD = 0.9;
export const EGE_LABEL = 'EGE';
export const LEADS_LABEL = 'LEADs';
export const APP_NAME = 'LEADs';

export const NOTIFICATION_TYPE_TITLE = {
  MOTD: 'Message of the Day',
  JOB_UPDATE: 'Job Update',
  ALERT: 'Alert',
  TACKLEBOX: 'Tacklebox'
};

export const MENU_PROPS_OPEN_TO_LEFT: Partial<Omit<MenuProps, 'children'>> = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
};

export const NAVBAR_VIEWS: NavbarOptions = {
  // home: { displayName: 'Home', icon: SsidChartOutlined, path: '/', group: 'main' },
  flycaster: { displayName: 'Flycaster', icon: FactCheckOutlined, path: '/', group: 'main', enabled: true },
  tacklebox: { displayName: 'Tacklebox', icon: HomeRepairServiceOutlined, path: '/tacklebox', group: 'main', enabled: true },
  // alerts: { displayName: 'Alerts', icon: ListAltOutlined, path: '/', group: 'main' },

  datasets: { displayName: 'Data Stats', icon: SsidChartOutlined, path: '/stats/datasets', group: 'stats', enabled: false },
  dictionary: { displayName: 'Data Dictionary', icon: ListAltOutlined, path: '/stats/dictionary', group: 'stats', enabled: false },
  integrity: { displayName: 'Data Integrity', icon: FactCheckOutlined, path: '/stats/integrity', group: 'stats', enabled: false }
};

interface TopicColor {
  light: string;
  dark: string;
  mapPolygonFillColor: [number, number, number, number];
  mapPolygonBorderColorSelected: [number, number, number, number];
}
export type Topic = 'military' | 'diplomatic' | 'university';
export const TOPIC_COLORS: Record<Topic, TopicColor> = {
  military: {
    light: '#FFD5D5',
    dark: '#FF8080',
    mapPolygonFillColor: hexToRgb('#FF8080', 120),
    mapPolygonBorderColorSelected: hexToRgb('#FF8080', 255)
  },
  diplomatic: {
    light: '#b3bfdd',
    dark: '#4963a1',
    mapPolygonFillColor: hexToRgb('#4963a1', 120),
    mapPolygonBorderColorSelected: hexToRgb('#4963a1', 255)
  },
  university: {
    dark: '#D9D0C9',
    light: '#F2EFE9',
    mapPolygonFillColor: hexToRgb('#D9D0C9', 120),
    mapPolygonBorderColorSelected: hexToRgb('#D9D0C9', 255)
  }
};
export const TOPIC_COLOR_UNKNOWN = '#FFFF00';
export const TOPIC_COLOR_UNKNOWN_RGB = [255, 255, 0, 255] as [number, number, number, number];
export const TOPICS: Topic[] = ['military', 'diplomatic', 'university'];
export const MAX_ANIMATED_ZOOM = 13.5;
export const CENTROID_TO_BOUNDING_BOX_OFFSET = 0.005;
