import { useAuth0 } from '@auth0/auth0-react';
import { AUTH_FALLBACK } from '@const';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { sec } from './security';
import ReactLazyLoading from '@components/ReactLazyLoading';

const { VITE_DISABLE_AUTH } = import.meta.env;

export interface AppAuthProps {
  children: React.ReactNode | React.ReactNode[];
}
function AppAuth({ children }: AppAuthProps) {
  const {
    // \n
    error,
    getAccessTokenSilently,
    isLoading,
    loginWithRedirect,
    user,
    logout
  } = VITE_DISABLE_AUTH ? AUTH_FALLBACK : useAuth0(); // eslint-disable-line react-hooks/rules-of-hooks
  window.logout = logout;
  sec.setAccessTokenSilently(getAccessTokenSilently);

  useEffect(() => {
    if (error) {
      toast.error('failed to authenticate w/ auth0, check console for error');
      return console.error(error);
    }
    if (!isLoading && !user) {
      loginWithRedirect();
    }
  }, [user, isLoading, error, loginWithRedirect]);

  const url = new URL(window.location.href);
  const redirectPath = url.searchParams.get('path');

  // keep the initial redirect path in state,
  // when auth0 completes the login, it clears
  // the search params :(
  const [redir] = useState(redirectPath);

  // check url parameters for `path`:
  useEffect(() => {
    if (redir && user) {
      // Navigate to the new path
      window.location.href = redir;

      // Optionally, if you want to remove the query parameter after navigation
      // and stay on the current page without reloading
      window.history.replaceState(null, '', redirectPath);
    }
  }, [redir, redirectPath, user]);

  // don't render anything until we know if the user is authenticated:
  if (isLoading || !user || redir) {
    return <ReactLazyLoading />;
  }

  return children;
}

export default AppAuth;
