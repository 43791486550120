export type FilterColumns = 'datasource' | 'date' | 'country' | 'wifi status';

export interface Filter {
  column: null | FilterColumns;
  values: unknown;
}

export enum ChartXAxisOptions {
  DATE = 'daily (UTC)',
  COUNTRY = 'country',
  HOURLY = 'hour of day (UTC)',
  WEEKLY = 'weekly (UTC)',
  MONTHLY = 'monthly (UTC)'
}
export type ChartXAxisOption = (typeof ChartXAxisOptions)[keyof typeof ChartXAxisOptions];

export enum ChartYAxisGroups {
  HAS_WIFI = 'wifi status',
  COUNTRY = 'country',
  DATASOURCE = 'datasource'
}
export type ChartYAxisGroup = (typeof ChartYAxisGroups)[keyof typeof ChartYAxisGroups];

export enum ChartDateFields {
  DELIVERED = 'delivery_date',
  RECORDED = 'record_date'
}
export type ChartDateField = (typeof ChartDateFields)[keyof typeof ChartDateFields];

export enum CandyCaneYAxisType {
  LOG = 'log',
  LINEAR = 'linear'
}

export type StatsRequest = {
  filters: Filter[];
  chartv2_x_axis: ChartXAxisOption;
  chartv2_y_group: null | ChartYAxisGroup;
  chartv2_date_field: ChartDateField;
};

export type StatsRequestTable = {
  filters: Filter[];
  chartv2_date_field: ChartDateField;
  tablev2_include_date?: boolean;
  tablev2_include_geom?: boolean;
  tablev2_include_wifi?: boolean;
  tablev2_include_hod?: boolean;
  tablev2_include_datasource?: boolean;
};

export interface GadmGeoJSONProperties {
  ISO3: string;
  ISO2: string;
  NAME: string;
  POP2005: number;
}

export interface StatsGeometry {
  type: string;
  coordinates: number[][][][];
}

export interface GadmGeoJSONFeature {
  type: string;
  properties: GadmGeoJSONProperties;
  geometry: StatsGeometry;
}

export interface CrsProperties {
  name: string;
}

export interface StatsCrs {
  type: string;
  properties: CrsProperties;
}

export interface GadmGeoJSONResponse {
  type: 'FeatureCollection';
  crs: StatsCrs;
  features: GadmGeoJSONFeature[];
}
export interface GadmL0ResponseRow {
  name: string;
  iso3: string;
}
export interface GadmL0Response {
  lookup: Record<string, string>;
  items: GadmL0ResponseRow[];
}

export enum ChartYAxisOptions {
  COUNT = 'count',
  COUNT_PER_CAPITA = 'count per capita',

  APPROX_ADID_COUNT = 'unique adid‡',
  APPROX_WIFI_SSID_COUNT = 'unique wifi ssid‡',
  APPROX_WIFI_BSSID_COUNT = 'unique wifi bssid‡',

  APPROX_ADID_COUNT_PER_CAPITA = 'unique adid per capita‡',
  APPROX_WIFI_SSID_COUNT_PER_CAPITA = 'unique wifi ssid per capita‡',
  APPROX_WIFI_BSSID_COUNT_PER_CAPITA = 'unique wifi bssid per capita‡'
}

export type ChartYAxisOption = (typeof ChartYAxisOptions)[keyof typeof ChartYAxisOptions];

export interface GADMCountry {
  name: string;
  country_code: string;
}

export interface RowboatDatasource {
  short_name: string;
  name: string;
  min_date: string;
  max_date: string;
  column_names: string[];
  long_description_md: string;
  count?: string;
  unique_ids?: string;
}

export interface RowboatDatasourceStats {
  datasource: string;
  file_count: string;
  file_size: string;
}

export const OVERLAP_DIMENSIONS = ['adids', 'bssids', 'ssids'];
export type OVERLAP_DIMENSIONST = (typeof OVERLAP_DIMENSIONS)[number];

export interface DataTrailsOverlapResponse {
  venn: Record<OVERLAP_DIMENSIONST, string>;
}

export interface DataTrailsOverallResponseRaw {
  datasource: string;
  deliveries: Record<string, number>;
}

export interface DataTrailsOverallResponse extends DataTrailsOverallResponseRaw {
  deliveries_pct: Record<string, number>;
}
export interface CountryV2 {
  name: string;
  code: string;
}

export interface DataTrailsRequest {
  datasource: string[];
  dateRange?: string[];
  wifiStatus?: string;
  countries?: string[];
}

export interface DataTrailsResponse {
  delivery_lag: Record<string, Record<string, number>>;
  datasource: string;
}

export interface DatasourceFileTransferSummaryDate {
  date: string;
  bytes: string;
  count: string;
  possible_problem: boolean;
  pending: boolean;
}
export interface DatasourceFileTransferStatusResponse {
  [key: string]: DatasourceFileTransferSummaryDate[];
}

export interface DataSinkValue {
  value: number;
  count: string;
  date: string;
}

export interface DataSinkResponse {
  [key: string]: DataSinkValue[];
}
export interface ImpossibleJourneyValue {
  value: number;
  count: string;
  date: string;
}
export interface ImpossibleJourneyResponse {
  [key: string]: ImpossibleJourneyValue[];
}

export interface OverlapRow {
  a: string;
  b: string;
  overlap_percentage: number;
  run_id: string;
  created_at: string;
}
