import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { Audience, MapViewport } from '@types';
import { RootState } from '@store';
import { LngLatBoundsLike } from 'react-map-gl';

export interface SliceState {
  delayedMapViewport?: MapViewport;
  delayedMapBounds?: [[number, number], [number, number]];
  fitBounds?: null | LngLatBoundsLike;
  project_id?: string;
  audience_ids?: string[];
  selectedAudiences: Audience[];
}

const initialState: SliceState = {
  selectedAudiences: []
};

export const slice = createSlice({
  name: 'entityAnalysisSlice',
  initialState,
  reducers: {
    fitBoundsOnMap: (state, action: PayloadAction<LngLatBoundsLike>) => {
      state.fitBounds = action.payload;
    },
    clearState: (state) => {
      state.project_id = undefined;
      state.audience_ids = undefined;
    },
    initializeState: (state, action: PayloadAction<{ project_id: string; audience_ids: string[] }>) => {
      state.project_id = action.payload.project_id;
      state.audience_ids = action.payload.audience_ids;
    },
    setDelayedMapViewport: (state, action: PayloadAction<{ viewport: MapViewport; bounds: [[number, number], [number, number]] }>) => {
      state.delayedMapViewport = action.payload.viewport;
      state.delayedMapBounds = action.payload.bounds;
    },
    replaceSelectedAudiences: (state, action: PayloadAction<Audience[]>) => {
      state.selectedAudiences = action.payload;
    }
  }
});

const selectAudienceIds = (store: RootState) => store.entityAnalysisSlice.audience_ids;
const selectProjectId = (store: RootState) => store.entityAnalysisSlice.project_id;
const selectDelayedMapViewport = (store: RootState) => store.slice.delayedMapViewport;
const selectInitialMapViewport = (store: RootState) => store.slice.initialMapViewport;
const selectFitBounds = (store: RootState) => store.entityAnalysisSlice.fitBounds;
const selectSelectedAudiences = (store: RootState) => store.entityAnalysisSlice.selectedAudiences;

const sidebarSelector = createSelector([selectAudienceIds, selectProjectId, selectSelectedAudiences], (audienceIds, projectId, selectedAudiences) => ({
  audienceIds,
  projectId,
  selectedAudiences
}));

const mapSelector = createSelector(
  [selectProjectId, selectInitialMapViewport, selectDelayedMapViewport, selectFitBounds, selectSelectedAudiences],
  (projectId, initialMapViewport, delayedMapViewport, fitBounds, selectedAudiences) => ({
    projectId,
    initialMapViewport,
    delayedMapViewport,
    fitBounds,
    selectedAudienceIds: selectedAudiences.map((audience) => audience.advertiser_id)
  })
);

export const sliceSelectors = {
  sidebarSelector,
  mapSelector
};

// Action creators are generated for each case reducer function
export const {
  // \n
  clearState,
  initializeState,
  setDelayedMapViewport,
  fitBoundsOnMap,
  replaceSelectedAudiences
} = slice.actions;

export default slice.reducer;
