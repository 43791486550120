import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

// injections: slices
import sliceReducer from './slice';
import entityAnalysisReducer from './Views/EntityAnalysis/slice';
import statsReducer from './Views/Stats/slice';
import tackleboxReducer from './Views/Tacklebox/slice';

// injections: services
import { service } from './service';
import { api } from './api';
import { api as statsApi } from './Views/Stats/api.ts';
import { service as statsService } from './Views/Stats/service.ts';
import { staticService } from './staticService.ts';
import { service as tackleboxService } from './Views/Tacklebox/service.ts';

export const store = configureStore({
  reducer: {
    // slices
    slice: sliceReducer,
    entityAnalysisSlice: entityAnalysisReducer,
    statsSlice: statsReducer,
    tackleboxSlice: tackleboxReducer,

    // services
    [service.reducerPath]: service.reducer,
    [api.reducerPath]: api.reducer,
    [statsApi.reducerPath]: statsApi.reducer,
    [staticService.reducerPath]: staticService.reducer,
    [statsService.reducerPath]: statsService.reducer,
    [tackleboxService.reducerPath]: tackleboxService.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      // cont'd services
      service.middleware,
      api.middleware,
      statsApi.middleware,
      staticService.middleware,
      statsService.middleware,
      tackleboxService.middleware
    ])
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
