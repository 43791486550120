import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { sec } from '../../security';

const { VITE_SERVICE_HOST } = import.meta.env;

// Define a service using a base URL and expected endpoints
export const service = createApi({
  tagTypes: [],
  reducerPath: 'tackleboxService',
  baseQuery: fetchBaseQuery({
    baseUrl: VITE_SERVICE_HOST,
    prepareHeaders: async (headers) => {
      const access_token = await sec.getAccessTokenSilently()();
      if (access_token) headers.set('Authorization', `Bearer ${access_token}`);
      return headers;
    }
  }),
  endpoints: () => ({})
});

// export const { } = service;
