import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { sec } from './security';
import { EGESlimCacheRequest, EGESlimCacheResponse, EntityProfileActivityResponse, EntityProfileRequest, EntityProfileResponse } from '@types';

const { VITE_API_HOST } = import.meta.env;

// Define a service using a base URL and expected endpoints
export const api = createApi({
  tagTypes: [],
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: VITE_API_HOST,
    prepareHeaders: async (headers) => {
      const access_token = await sec.getAccessTokenSilently()();
      if (access_token) headers.set('Authorization', `Bearer ${access_token}`);
      headers.set('Content-Type', 'application/json');
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getEGEData: builder.query<EGESlimCacheResponse[], EGESlimCacheRequest>({
      // don't cache data... it's too big!
      keepUnusedDataFor: 1,
      query: ({ rowboat_table_identifier, bounds, advertiser_ids }) => {
        return {
          method: 'POST',
          url: `/ege/cache/${rowboat_table_identifier}`,
          body: {
            slim: 'true',
            bounds,
            advertiser_ids: advertiser_ids ? advertiser_ids.join(';') : undefined,
            min_date: '2023-01-01'
          }
        };
      }
    }),
    getDataCancel: builder.mutation<void, string>({
      query: (request_identifier) => ({
        method: 'DELETE',
        url: '/',
        params: {
          request_identifier
        }
      })
    }),
    getEntityProfileActivity: builder.query<EntityProfileActivityResponse[], EntityProfileRequest>({
      query: ({ entity_id, date_range }) => {
        let url = `/entity/profile/${entity_id}/activity?date_start=1986-01-01`;
        if (date_range && date_range.length === 2) {
          url += `?date_start=${encodeURIComponent(date_range[0])}&date_end=${encodeURIComponent(date_range[1])}`;
        }
        return {
          method: 'GET',
          url
        };
      }
    }),
    getEntityProfile: builder.query<EntityProfileResponse, EntityProfileRequest>({
      query: ({ entity_id, date_range }) => {
        let url = `/entity/profile/${entity_id}?min_date=1986-01-01`;
        if (date_range && date_range.length === 2) {
          url += `?date_start=${encodeURIComponent(date_range[0])}&date_end=${encodeURIComponent(date_range[1])}`;
        }
        return {
          method: 'GET',
          url
        };
      }
    })
  })
});

export const {
  // \n
  useGetEntityProfileQuery,
  useGetDataCancelMutation,
  useGetEntityProfileActivityQuery,
  useGetEGEDataQuery
} = api;
